import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

const ReviewTile = ({ title, body, avatarImg, authorName, authorRole }) => {
  const renderTitle = () => {
    return (
      <Typography
        variant='headline'
        component='h3'
        fontWeight='semibold'
        data-testid='home-page-review-title'
      >
        {title}
      </Typography>
    )
  }

  const renderBody = () => {
    return (
      <Typography
        variant='body'
        component='p'
        data-testid='home-page-review-body'
      >
        {body}
      </Typography>
    )
  }

  const renderAvatarImage = () => {
    if (avatarImg?.src) {
      return (
        <SafeImage
          src={withAssetPrefix(avatarImg?.src)}
          alt='author avatar'
          width={44}
          height={44}
        />
      )
    }
    return (
      <div className='flex flex-center size-[44px] bg-grey-200 rounded-full'>
        {avatarImg?.fallbackText}
      </div>
    )
  }

  const renderAuthorSection = () => {
    return (
      <div className='flex items-center mt-auto gap-3'>
        {renderAvatarImage()}
        <div>
          <Typography
            variant='subhead'
            component='p'
            fontWeight='semibold'
            className='mb-1'
          >
            {authorName}
          </Typography>
          <Typography
            variant='footnote'
            component='p'
          >
            {authorRole}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div
      className='flex flex-col gap-4 p-5 my-3 bg-white-300 shadow-2 rounded-2'
      data-testid='home-page-review'
    >
      {renderTitle()}
      {renderBody()}
      {renderAuthorSection()}
    </div>
  )
}

ReviewTile.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
  avatarImg: PropTypes.shape({
    src: PropTypes.string,
    fallbackText: PropTypes.string
  }).isRequired
}

export default ReviewTile
