import MobileSwipeableRail from '@components/mobile_swipeable_rail'
import Placeholder from '@components/placeholder'
import ReviewTile from '@components/review_tile'
import { getIsLoggedIn } from '@helpers/auth'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import { getHomePageReviews } from './helpers'

const HomePageReviews = () => {
  const { t } = useI18n()
  const { data, loading } = useUserData()
  const isLoggedIn = getIsLoggedIn(data)

  if (isLoggedIn) return null

  const renderPlaceholders = () => {
    if (!loading) return null

    return Array.from({ length: 4 }).map((_, index) => {
      return (
        <Placeholder
          key={index}
          width='284'
          height='338'
        />
      )
    })
  }

  const renderReviewTiles = () => {
    if (loading) return null

    const reviews = getHomePageReviews(t)

    return reviews.map((review, index) => {
      return (
        <ReviewTile
          key={index}
          title={review.title}
          body={review.body}
          authorName={review.authorName}
          authorRole={review.authorRole}
          avatarImg={review.avatarImg}
        />
      )
    })
  }

  return (
    <MobileSwipeableRail
      mobileItemWidth={270}
      desktopItemWidth={284}
      className='mb-5 lg:mb-9'
    >
      {renderPlaceholders()}
      {renderReviewTiles()}
    </MobileSwipeableRail>
  )
}

export default HomePageReviews
