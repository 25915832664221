export const getHomePageReviews = (t) => {
  return [
    {
      title: t('d_hp_review_title_1', { fallback: 'By far the best' }),
      body: t('m_hp_review_body_1', {
        fallback:
          'Student Beans is by far the best student app there is. From giving free stuff, to giving amazing discounts of high value, and in-store offers, they have everything you possibly need.'
      }),
      authorName: 'Avenoor Gulati',
      authorRole: t('d_first_year_student', {
        fallback: 'First year undergraduate student'
      }),
      avatarImg: { src: '/images/review_avatar_1.png', fallbackText: 'AG' }
    },
    {
      title: t('d_hp_review_title_2', { fallback: 'Life changing' }),
      body: t('m_hp_review_body_2', {
        fallback:
          'Living in London on a student budget is getting more difficult, but services like Student Beans are genuinely life changing! I feel that students should really make use of the wide range of products and services offered to save up a consistent amount of money.'
      }),
      authorName: 'Luce Biscardi',
      authorRole: t('d_second_year_student', {
        fallback: 'Second year undergrad student'
      }),
      avatarImg: { fallbackText: 'LB' }
    },
    {
      title: t('d_hp_review_title_3', { fallback: 'Helps student save' }),
      body: t('m_hp_review_body_3', {
        fallback:
          'I think Student Beans is great and provides quick access to discounts that helps students save when they’re already struggling.'
      }),
      authorName: 'Rasane Rajib',
      authorRole: t('d_foundation_year_student', {
        fallback: 'Foundation year student'
      }),
      avatarImg: { fallbackText: 'RR' }
    },
    {
      title: t('d_hp_review_title_4', { fallback: 'Love Student Beans' }),
      body: t('m_hp_review_body_4', {
        fallback:
          'Love, Love, LOVE Student Beans. They have helped me out on so many different occasions. Their discount codes and blogs are super encompassing. I totally see their attempts to help us thrive.'
      }),
      authorName: 'Natasha Chapman',
      authorRole: t('d_third_year_student', {
        fallback: 'Third year undergrad student'
      }),
      avatarImg: { fallbackText: 'NC' }
    }
  ]
}
